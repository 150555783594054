<template>
  <div>
    <video id="myVideo" ref="myVideo" controls  class="video-js vjs-big-play-centered video_css">
      <source
        :src="videoUrl"
        :type="videoType">
    </video>
  </div>
</template>
<script>
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-hls'

export default {
  props:{
    url:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      playHandler: null,
       // type格式内容  m3u8:application/x-mpegURL,mp4:video/mp4， flv:video/x-flv,mov:rtmp/mp4
      videoType:'', 
      videoUrl:'',
    }
  },
  mounted(){
     setTimeout(() => {
      this.videoUrl = this.url
      this.initVideo()
    }, 300)
  },
  computed:{
  },
  methods:{
    async initVideo() {
      this.$nextTick(() => {
        this.playHandler = Video('myVideo', {
        // 确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
          controls: true,
          // 自动播放属性,muted:静音播放
          autoplay: true,
          playbackRates: [0.2, 0.5, 1, 1.5, 2, 2.5, 3],
          // 建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
          preload: 'auto',
          // 设置视频播放器的显示宽度（以像素为单位）
          width: '100%',
          // 设置视频播放器的显示高度（以像素为单位）
          height: ''
        })
      })
    },
  },
}
</script>
<style scoped>
.video_css {
  width: 100vw;
  height: calc(100vh - 100px);
  object-fit: contain;
  margin: 0;
  padding: 0;
}
</style>
import api from './api.js'

// 获取班级信息
// GathererID：1:所有班级 2：任教班级 3：任班主任班级 4：某学科的任教班级
export async function getMyClass(data) {
  return await api.post('getMyClass', data)
}
// 获取班级人员信息（老师、学生）
// ClassID :班级编号
// rType：0：全部 1：仅学生 3：仅老师
export async function GetClassUserList(data) {
  return await api.post('GetClassUserList', data)
}
// 获取班级请假人员列表
export async function getClassLeaveUser(data) {
  return await api.get('getClassLeaveUser', data)
}
// 查询我创建的小组
export async function getMyGroup(data) {
  return await api.get('getMyGroup', data)
}
// 新增/修改小组
export async function manageMyGroup(data) {
  return await api.post('manageMyGroup', data)
}
// 增加小组成员
export async function AddClassGroupDetailed(data) {
  return await api.post('AddClassGroupDetailed', data)
}
// 解散小组
export async function DelMyGroup(data) {
  return await api.post('DelMyGroup', data)
}
// 根据组ID查询组内的人员
export async function getGroupPerson(data) {
  return await api.get('getGroupPerson', data)
}
// 查询点评项，顶级 pID传0
export async function getEvaluation(data) {
  return await api.get('getEvaluation', data)
}
// 发送评价
export async function EvaluationSave(data) {
  return await api.post('EvaluationSave', data)
}
// 获取签到记录
export async function getAttendanceRecord(data) {
  return await api.post('getAttendanceRecord', data)
}
// 获取第几次签到调用
export async function getAttendanceTime(data) {
  return await api.get('getAttendanceTime', data)
}
// 新增签到记录
export async function attendanceRecordSave(data) {
  return await api.post('attendanceRecordSave', data)
}
export async function attendanceRecordSave2(data) {
  return await api.postA('attendanceRecordSave2', data, {
    content: 'application/json',
  })
}
// 查询某段时间内未被此老师点过名的人
export async function getNoRollCallUser(data) {
  return await api.get('getNoRollCallUser', data)
}
// 新增点名记录
export async function rollCallRecordSave(data) {
  return await api.post('rollCallRecordSave', data)
}
// 查询常用视频和链接
export async function ClassCommonLinks(data) {
  return await api.get('ClassCommonLinks', data)
}
// 查询作业，公告
export async function getClassTask(data) {
  return await api.get('getClassTask', data)
}
export async function getClassStudentScoreRankingReport(data) {
  return await api.get('ClassStudentScoreRankingReport', data)
}
export async function getGradeAttendanceReportV4(data) {
  return await api.post('GradeAttendanceReportV4', data)
}

// 获取学生报告
export async function StudentReportTotalRanking(data) {
  return await api.get('StudentReportTotalRanking', data)
}
// 获取学生数据统计(旧)
export async function StudentReportSubitemRanking(data) {
  return await api.get('StudentReportSubitemRanking', data)
}
//获取学生数据统计(新)
export async function StudentReportSubitemRanking2(data) {
  return await api.get('StudentReportSubitemRanking2', data)
}
// 获取学生德智体美劳分项成长趋势
export async function StudentReportGrowthTrends(data) {
  return await api.get('StudentReportGrowthTrends', data)
}
// 提醒铃铛
export async function StudentReportWarn(data) {
  return await api.get('StudentReportWarn', data)
}
// 获取学生实践活动
export async function GetStudentPractice(data) {
  return await api.get('GetStudentPractice', data)
}
export async function GetStudentScoreByTask(data) {
  return await api.get('GetStudentScoreByTask', data)
}

// 查询学生做过的有积分的任务
export async function GetStudentListByScore(data) {
  return await api.get('GetStudentListByScore', data)
}
// 查询学生学业成绩等数据
export async function GetDataImportMainList(data) {
  return await api.get('GetDataImportMainList', data)
}
// 查询学生学业成绩等数据明细项
export async function GetDataImportDetailList(data) {
  return await api.get('GetDataImportDetailList', data)
}
// 查询学生阶段性评价语
export async function GetStudentComment(data) {
  return await api.get('GetStudentComment', data)
}
// 修改学生阶段性评价语
export async function upStudentComment(data) {
  return await api.post('upStudentComment', data)
}

// 查询学生个人信息
export async function StudentReportInfo(data) {
  return await api.get('StudentReportInfo', data)
}

// 获取登录二维码
export async function LoginMa(key, ClassID) {
  return await api.get('LoginMa?key=' + key + '&classID=' + ClassID)
}

// 编辑常用视频和网站
export async function ClassCommonLinksEdit(data) {
  return await api.postA('ClassCommonLinksEdit', data, {
    content: 'multipart/form-data',
  })
}
// 删除常用视频和网站
export async function ClassCommonLinksDelete(data) {
  return await api.post('ClassCommonLinksDelete', data)
}
// 登录
export async function Login(data) {
  return await api.post('Login', data)
}

// 全校德智体美劳
export async function GradeReportGrowthTrends(data) {
  return await api.get('GradeReportGrowthTrends', data)
}
// 某段时间内全校德智体美劳分项得分
export async function SchoolReportGrowthTrends2(data) {
  return await api.get('SchoolReportGrowthTrends2', data)
}
// 各年级某个时间各班级总得分 (德智体美劳合计) 排名
export async function ClassScoreRankingReport(data) {
  return await api.get('ClassScoreRankingReport', data)
}
// 各年级某个时间学生总得分(德智体美劳合计) 排名
export async function StudentScoreRankingReport(data) {
  return await api.get('StudentScoreRankingReport', data)
}
// 各年级某个时间评价人评价次数
export async function EvaluatorScoreRankingReport(data) {
  return await api.get('EvaluatorScoreRankingReport', data)
}
// 全校人数
export async function SchoolUserData(data) {
  return await api.get('SchoolUserData', data)
}
export async function GetNewEvaluationData(data) {
  return await api.get('GetNewEvaluationData', data)
}

//系统生成评语
export async function BaiDuAiSummary(data) {
  return await api.get('BaiDuAiSummary', data)
}

//2024-08-29 新增
//获取班级就餐列表
export async function getlunchRecordBvTime(data) {
  return await api.get('getLunchRecordByTime', data)
}

//提交午餐就餐
export async function addlunchRecordBvTime(data) {
  return await api.post('upLunchRecord', data)
}

//更新某一位学生就餐状态
export async function updatelunchRecordBvTime(data) {
  return await api.post('upLunchRecordByID', data)
}
// // 查询
// export async function getbiddingProject(data,QueryParams) {
// 	return await api.get('biddingProject?queryParams=' + QueryParams,data);
// }
// // 新增
// export async function postproduct(data,res) {
// 	return await api.put('product',data);
// }
// // 修改
// export async function putproduct(data,res) {
// 	return await api.put(`product/${res}`,data);
// 	// return await api.put('product/' + res,data);
// }
// // 删除
// export async function delproduct(res) {
// 	return await api.del('product?pIds=' + res);
// }

<template>
    <div>
        <el-dialog :title="title" :visible.sync="dialogVisible" append-to-body width="50%">
            <div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="标题" prop="clTitle">
                        <el-input v-model="ruleForm.clTitle" maxlength="50"></el-input>
                    </el-form-item>
                    
                    <el-form-item label="缩略图" prop="imgurl">
                        <el-upload
                            class="avatar-uploader"
                            action="#"
                            :show-file-list="false"
                            :auto-upload="false"
                            :on-change="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                            accept=".jpg,.png,.jpeg"
                        >
                            <img v-if="ruleForm.imgurl" :src="ruleForm.imgurl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item :label="ruleForm.clType == 1 ? '视频地址' : '网站地址'" prop="toUrl">
                        <el-input v-model="ruleForm.toUrl" maxlength="200"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { ClassCommonLinksEdit } from "@/api/index.js";
let formData = new FormData()
export default {
    data() {
        return {
            dialogVisible: false,
            title: '新增常用视频',
            ruleForm: {
                clTitle: '', // 标题
                CLID: '0', // 主键
                clType: 1, // 类型
                classID: localStorage.getItem('classID'), // 班级ID
                toUrl: '', // 视频地址
                imgurl: '' // 视频缩略图
            },
            rules: {
                clTitle: [
                    { required: true, message: '请输入标题', trigger: 'blur' }
                ],
                imgurl: [
                    { required: true, message: '请上传缩略图', trigger: 'change' }
                ],
                toUrl: [
                    { required: true, message: '请输入地址', trigger: 'blur' }
                ]
            }
        }
    },
    methods: {
        handleAvatarSuccess(file) {
            formData.set('file',file.raw)
            this.ruleForm.imgurl = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            console.log(file)
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
              this.$message.error('上传图片大小不能超过 2MB!');
            }
            return isLt2M;
        },
        openDe(type,item){
            formData.delete('file')
            this.ruleForm = {
                clTitle: '', // 标题
                CLID: '0', // 主键
                clType: 1, // 类型
                classID: localStorage.getItem('classID'), // 班级ID
                toUrl: '', // 视频地址
                imgurl: '' // 视频缩略图
            }
            this.dialogVisible = true
            let title = ''
            if(item == 0){
                title += '新增'
                this.ruleForm.CLID = 0
            } else {
                title += '修改'
                this.ruleForm.CLID = item.CLID
                this.ruleForm.clTitle = item.title
                this.ruleForm.toUrl = item.toUrl
                this.ruleForm.imgurl = item.imgurl
            }
            if(type == 1){
                title += '常用视频'
            } else {
                title += '常用网站'
            }
            this.title = title
            this.ruleForm.clType = type 
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForm.classID = localStorage.getItem('classID')
                    let datas = this.ruleForm
                    formData.set('CLID',datas.CLID)
                    formData.set('clType',datas.clType)
                    formData.set('classID',datas.classID)
                    formData.set('clTitle',datas.clTitle)
                    formData.set('toUrl',datas.toUrl)
                    ClassCommonLinksEdit(formData).then(res =>{
                        this.$message.success(this.ruleForm.CLID == 0 ? '新增成功' : '修改成功');
                        this.dialogVisible = false
                        this.$parent.getList()
                    })
                }
            });
        },
    }
}
</script>

<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }</style>
<!-- 常用视频 -->
<template>
  <div>
    <div style="height: 3rem"></div>
    <div class="box_css">
      <div class="box_left_css">
        <div class="box_title_css">
          <div>常用视频</div>
          <div v-if="Token&&classID">
            <el-button type="text" @click="videoTT = true" v-if="videoTT == false">编辑</el-button>
            <el-button type="text" @click="videoTT = false" v-if="videoTT == true">完成</el-button>
          </div>
        </div>
        <div class="box_item_css">
          <div v-for="(item, index) in list" :key="index" @click="drawerTrue(item)" >
            <img
              style="width:20rem;height:14.5rem;object-fit: cover;"
              :src="item.imgurl"
            />
            <div class="box_item_text_css">{{ item.title }}</div>
            <div class="abod_css" v-if="item.classID == classID&&videoTT">
              <el-button size="small" @click.stop="addVideo(1,item)" class="video_button_css" icon="el-icon-edit" type="primary"></el-button>
              <el-button size="small" @click.stop="dele(item.CLID)" class="video_button_css" type="danger" icon="el-icon-delete"></el-button>
            </div>
          </div>
          <div class="video_add_css" v-if="videoTT" @click="addVideo(1,0)">
            <i class="el-icon-plus"></i>
          </div>
          <i v-for="item in 5" :key="'id_' + item"></i>
        </div>
      </div>
      <div>
        <div class="box_right_css">
          <div class="box_title_css">通知公告</div>
          <div class="box_right_itemA_css">
            <div v-for="(item, index) in noticeList" :key="index" @click="noticeVisibleTrue(item)" >
              <div>
                <div :style="{'background-color': item.show ? 'red' : ''}" class="hongdian"></div>
                <div class="one_css">{{ item.hwTitle }}</div>
              </div>
              <div>{{ moment(item.sendTime).format('YYYY-MM-DD') }}</div>
            </div>
          </div>
        </div>
        <div class="box_right_css" style="margin-top: 1.35rem;">
          <div class="box_title_css">
            <div>常用链接</div>
            <div v-if="Token&&classID">
              <el-button type="text" @click="linkTT = true" v-if="linkTT == false">编辑</el-button>
              <el-button type="text" @click="linkTT = false" v-if="linkTT == true">完成</el-button>
            </div>
          </div>
          <div class="box_right_itemB_css">
            <div v-for="(item, index) in CommonLink" :key="index" @click="OpenWebPage(item.toUrl)">
              <img :src="item.imgurl" />
              <div>{{ item.title }}</div>
              <div class="abod_cssA" v-if="item.classID == classID&&linkTT">
                <el-button size="small" @click.stop="addVideo(2,item)" class="comm_button_css" icon="el-icon-edit" type="primary"></el-button>
                <el-button size="small" @click.stop="dele(item.CLID)" class="comm_button_css" type="danger" icon="el-icon-delete"></el-button>
              </div>
            </div>
            <div v-if="linkTT" class="video_add_css CommonLink_add_css" @click="addVideo(2,0)">
              <i class="el-icon-plus"></i>
            </div>
            <i v-for="item in 5" :key="'id_' + item"></i>
          </div>
        </div>
      </div>
    </div>

    <el-drawer
      title=""
      :visible.sync="drawer"
      append-to-body
      size="100%"
      direction="btt"
      :with-header="false"
    >
      <div class="top_drawer_css">
        <div></div>
        <div>{{drawerObj.title}}</div>
        <div>
          <el-button type="danger" @click="drawer = false">关闭视频</el-button>
        </div>
      </div>
      <div>
        <video
          autoplay
          controls
          class="video_css"
          v-if="drawer"
          :src="drawerObj.toUrl"
        ></video>
        <!-- <videoPaly v-if="drawer" :url="drawerObj.toUrl" /> -->
      </div>
    </el-drawer>

    <el-drawer
      title=""
      :visible.sync="drawerB"
      append-to-body
      size="100%"
      direction="btt"
      :with-header="false"
    >
      <el-button class="top_drawer_cssB" type="danger" @click="drawerB = false">关闭视频</el-button>
      <div>
        <video
          autoplay
          controls
          class="video_cssB"
          v-if="drawerB"
          :src="drawerObj.imgurl"
        ></video>
        <!-- <videoPaly v-if="drawerB" :url="drawerObj.imgurl" /> -->
      </div>
    </el-drawer>

    <el-dialog
      title="通知公告详情"
      :visible.sync="noticeVisible"
      append-to-body
      width="50%">
      <div class="dotitle_css">
        {{noticeObj.hwTitle}}
      </div>
      <div class="dotime_css">
        {{noticeObj.sendTime}}
      </div>
      <div class="docontent_css">
        {{noticeObj.hwContent}}
      </div>
      <div class="box_item_img_css">
        <div v-for="(item,index) in noticeObj.allFiles" :key="index" class="box_image">
          <video
            :controls="false"
            @click="drawerObjClick(item.url)"
            v-if="['.mp4','.avi','.wmv','.mpg','.mpeg','.mov','.ram','.rm','.swf','.flv'].includes(item.Suffix)"
            style="object-fit: cover;width: 180px;height: 180px;border-radius: 5px;"
            :src="item.url"
          ></video>
          <el-image style="object-fit: cover;width: 180px;height: 180px;border-radius: 5px;" v-else :src="item.url" lazy :preview-src-list="urls" fit="cover"></el-image>
        </div>
        <i v-for="item in 5" :key="'id_' + item"></i>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="noticeVisible = false">确 定</el-button>
      </span>
    </el-dialog>

    <addCommonly ref="addCommonly" />
  </div>
</template>
<script>
import { ClassCommonLinks,getClassTask, ClassCommonLinksDelete } from "@/api/index.js";
import moment from 'moment';
import videoPaly from './videoPaly.vue'
import addCommonly from './addCommonly.vue'
export default {
  components:{
    videoPaly,
    addCommonly
  },
  data() {
    return {
      moment:moment,
      Token:localStorage.getItem('smartCampusToken'),
      videoTT: false,
      linkTT: false,
      classID: localStorage.getItem('classID'),
      drawer: false,
      noticeVisible: false,
      drawerB: false,
      drawerObj:{
        title:'',
        imgurl:''
      }, // 播放视频
      noticeObj:{
        hwTitle: '',
        hwContent: '',
        sendTime: '',
        allFiles:[]
      },
      noticeList: [], // 通知公告
      urls: [], // 预览图片
      CommonLink: [], // 网站
      list: [], // 视频
    };
  },
  created(){
    this.getList()
    getClassTask({
      ClassID: this.classID,
      startindex: 1,
			endindex: 8,
      startDate: moment().startOf('year').format("YYYY-MM-DD HH:mm"),
      endDate: moment().format('YYYY-MM-DD HH:mm'),
      hwType:5
    }).then((res) => {
      this.noticeList = res.data
    });
  },
  methods:{
    getList(){
      // 查询常用视频和链接
      ClassCommonLinks({
        ClassID: this.classID,
        clType: 1
      }).then((res) => {
        this.list = res.data
      });
      ClassCommonLinks({
        ClassID: this.classID,
        clType: 2
      }).then((res) => {
        this.CommonLink = res.data
      });
    },
    // 打开视频
    drawerTrue(item){
      this.drawerObj = item
      this.drawer = true
    },
    // 打开新的网页
    OpenWebPage(url) {
      window.open(url, '_blank')
    },
    // 打开通知公告
    noticeVisibleTrue(item){
      this.noticeObj = item
      this.noticeVisible = true
      this.urls = []
      this.noticeObj.allFiles.map(val =>{
        if(!['.mp4','.avi','.wmv','.mpg','.mpeg','.mov','.ram','.rm','.swf','.flv'].includes(val.Suffix)){
          this.urls.push(val.url)
        }
      })
    },
    drawerObjClick(url){
      this.drawerObj.imgurl = url
      this.drawerB = true
    },
    addVideo(type,item){
      this.$refs.addCommonly.openDe(type,item)
    },
    // 删除
    dele(id){
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ClassCommonLinksDelete({
          CLID: id
        }).then((res) => {
          this.getList()
        })
      }).catch(() => {})
    }
  }
};
</script>
<style scoped>
.box_css {
  display: flex;
  align-content: flex-start;
  justify-content: space-between;
  width: 1610px;
  height: calc(100vh - 230px);
  margin: 0 auto;
}
.box_left_css {
  background-color: #ffffffda;
  border-radius: 25px;
  width: 1000px;
  flex-shrink: 0;
  overflow: hidden;
}
.box_right_css{
  background-color: #ffffffda;
  border-radius: 25px;
  width: 580px;
  flex-shrink: 0;
  overflow: hidden;
  height: 48%;
}
.box_title_css {
  font-size: 26px;
  font-weight: bold;
  height: 60px;
  padding:0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box_item_css {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  overflow-y: auto;
  height: 650px;
}
.box_item_css > i {
  margin: 0 7px;
  width: 230px;
  height: 0px;
}
.box_item_css > div {
  margin: 0 7px 15px 7px;
  position: relative;
  width: 230px;
  height: 163px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}
.abod_css{
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 99;
  background-color: transparent !important;
}
.abod_cssA{
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 99;
  background-color: transparent !important;
}
.box_item_css > div > img {
  width: 230px;
  height: 163px;
  border-radius: 5px;
  margin: 0 !important;
  object-fit: cover;
}
.box_item_text_css {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.8);
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  word-wrap: break-word;
}
.box_right_itemB_css{
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  align-content: flex-start;
  height: 280px;
  padding: 0 10px;
}
.box_right_itemB_css>div{
  margin: 0 5px 10px 5px;
  position: relative;
  width: 100px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}
.box_right_itemB_css > div > img {
  width: 62px;
  height: 62px;
  border-radius: 5px;
  margin-bottom: 3px;
  object-fit: cover;
}
.box_right_itemB_css > div > div {
  height: 40px;
  font-size: 16px;
  text-align: center;
  line-height: 20px;
}
.top_drawer_css {
  display: flex;
  align-items: center;
  padding: 30px 50px;
  height: 100px;
  box-sizing: border-box;
}
.top_drawer_css > div {
  width: 150px;
  text-align: right;
  flex-shrink: 0;
}
.top_drawer_css > div:nth-child(2) {
  flex: 1;
  font-size: 30px;
  font-weight: bold;
  text-align: center !important;
}
.video_css {
  width: 100vw;
  height: calc(100vh - 100px);
  object-fit: contain;
  margin: 0;
  padding: 0;
}
.video_cssB {
  width: 100vw;
  height: 100vh;
  object-fit: contain;
  margin: 0;
  padding: 0;
}
.top_drawer_cssB{
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 99999;
}
.box_right_itemA_css{
  overflow-y: auto;
  height: 280px;
  padding: 0 20px 0 15px;
}
.box_right_itemA_css>div{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}
.box_right_itemA_css>div>div:nth-child(1){
 display: flex; 
 align-items: center;
}
.hongdian{
  width: 12px;
  height: 12px;
  border-radius: 100px;
  margin-right: 8px;
  flex-shrink: 0;
}
.one_css{
display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  word-wrap: break-word;
}
.box_right_itemA_css>div>div:nth-child(2){
  flex-shrink: 0;
  margin-left: 15px;
  color: #646262da;
}
.dotitle_css{
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  color: #000;
}
.dotime_css{
  text-align: center;
  font-size: 20px;
  color: #7c7a7a;
  margin-top: 5px;
}
.docontent_css{
  font-size: 20px;
  color: #363636;
  margin-top: 5px;
}
/deep/ .el-dialog__header{
    display: none;
}
.box_item_img_css {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  /* justify-content: center; */
}
.box_item_img_css > i {
  margin: 0 15px 0 0;
  width: 180px;
  height: 0px;
}
.box_image {
  margin: 0 15px 15px 0;
  position: relative;
  width: 180px;
  height: 180px;
  border-radius: 5px;
  overflow: hidden;
  
}
.box_image>img{
  object-fit: cover;
  width: 180px;
  height: 180px;
  border-radius: 5px;
}
.video_add_css{
  background-color: #ffffff;
  border: #cbb4ff 1px dashed;
  display: flex;
  align-items: center;
  justify-content: center;
}
.video_add_css>i{
  font-size: 40px;
  color: #976ff5;
}
.CommonLink_add_css{
  width: 82px !important;
  height: 82px !important;
  border-radius: 10px !important;
}
.video_button_css{
  height: 34px;
  padding: 5px 15px !important;
}
.comm_button_css{
  height: 34px;
  padding: 5px 5px !important;
}
</style>